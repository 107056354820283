<template>
  <div class="register">
    <PageNav title="易备注册" />
    <div class="register-wrap">
      <van-form @submit="onRegister" validate-trigger="onSubmit">
        <van-field
          v-model="phone"
          size="large"
          label="手机号"
          placeholder="手机号"
          autoComplete="off"
        />
        <van-field
          size="large"
          v-model="msgCode"
          type="number"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
          autoComplete="new-password"
        >
          <van-button slot="button" v-if="smsSec < 60" disabled size="small" type="default" plain color="#00a0ff" native-type="button">重新获取({{smsSec}}s)</van-button>
          <van-button slot="button" v-else :disabled="!smsAvailable" size="small" type="default" plain color="#00a0ff" @click="onGetSms" native-type="button">获取验证码</van-button>
        </van-field>
        <van-field
          size="large"
          type="password"
          v-model="password"
          label="密码"
          placeholder="密码"
          autoComplete="new-password"
        />
        <van-field
          size="large"
          v-model="company"
          label="公司/机构"
          placeholder="请输入公司/机构名称"
          autoComplete="off"
        />
        <van-field
          size="large"
          v-model="inviteCode"
          label="邀请码"
          placeholder="选填"
          autoComplete="off"
        />
        <div style="margin: 16px;">
          <van-checkbox v-model="checked" shape="square">我已阅读并同意<router-link to="/protocol">《易备用户服务使用协议》</router-link></van-checkbox>
        </div>
        <div style="margin: 16px;">
          <van-button :disabled="!registerAvailable" round block type="info" native-type="submit">注册</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PageNav from '@/components/PageNav';
import { Form, Field, Checkbox, Toast } from 'vant';
import utils from '@/common/utils';
import User from '@/common/user';
import Fetch from '@/common/fetch';
import { UserRegister, GetCaptchaCode } from '@/common/interface';

Vue.use(Form);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(Toast);

export default {
  name: "Login",
  components: { PageNav },
  data() {
    return {
      phone: '',
      password: '',
      smsSec: 60,
      msgCode: '',
      company: '',
      inviteCode: '',
      checked: false,
      interval: null,
    };
  },
  computed: {
    smsAvailable: function () {
      if (!(/^1[34578]\d{9}$/.test(this.phone)) || this.phone.length < 11) {      
        return false;        
      }
      return true;
    },
    registerAvailable () {
      if (this.smsAvailable && this.msgCode.length > 0 && this.password.length > 0 && this.company.length > 0 && this.checked) {
        return true;
      }
      return false;
    },
  },

  destroyed() {
    clearInterval(this.interval);
  },

  methods: {
    onBack() {
      this.$router.go(-1);
    },

    onGetSms () {
      this.getSmsCode();
      this.smsSec = 60;
      this.interval = setInterval(() => {
        if (this.smsSec <= 0) {
          this.smsSec = 60
          clearInterval(this.interval);
        } else {
          let newSec = this.smsSec - 1;
          if (newSec <= 0) {
            newSec = 0;
          }
          this.smsSec = newSec;
        }
      }, 1000);
    },

    getSmsCode() {
      const param = {
        phone: this.phone,
        checkType: 'REGISTER',
      };
      Fetch.post(GetCaptchaCode, param).then(() => {
      }).catch((err) => {
        Toast(err.msg);
      });
    },

    onRegister() {
      const param = {
        phone: this.phone,
        msgCode: this.msgCode,
        password: utils.md5(this.password),
        company: this.company,
        inviteCode: this.inviteCode,
      };

      Fetch.post(UserRegister, param).then((res) => {
        User.setInfo(res);
        if(this.$route.query.redirect){
          let redirect = this.$route.query.redirect;
          this.$router.replace(redirect);
        }else{
          this.$router.go(-1);
        }
      }).catch((err) => {
        const { msg } = err;
        Toast(msg);
      });
    },

    changeLoginType() {
      this.loginType = 1 ^ this.loginType;
    },
  },
}
</script>

<style lang="scss" scoped>
body {
  background: #fff;
}
.register-wrap {
  margin-top: 20px;
}
</style>